import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import SliderSwiper from "../components/sliderSwiper/sliderSwiper";
import News from "../components/iframes/news"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

const NoticiasPage = ({ data }) => {

  return (
    <>
      <SEO title="Notícias" image={data.globalJson.noticias.bg.childImageSharp.fluid.src} />
      <Layout>
        {useBreakpoint().mobile ? (
          <>
            <HeaderMobile
              data={data.globalJson.header}
              services={false}
            />

            <SliderSwiper data={[data.noticiasJson.introMobile]} center top />

            <News data={data.allMarkdownRemark.edges} bg={data.noticiasJson.introMobile.backgroundscroll} />
            
            <FooterMobile data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} />
          </>
        ) : (
          <>
            <Header
              data={data.globalJson.header}
              services={false}
            />

            <SliderSwiper data={[data.noticiasJson.intro]} center oneSlideBorder={false} top  />

            <News data={data.allMarkdownRemark.edges} bg={data.noticiasJson.introMobile.backgroundscroll} />

            <Footer data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} />
          </>
        )}
      </Layout>
    </>
  )
}

export default NoticiasPage

export const Json = graphql`
  query noticias {
    noticiasJson{
      intro {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        backgroundscroll {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      introMobile {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        backgroundscroll {
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
      }
      floatButtons{
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      noticias{
        bg {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bgMobile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(quality: 70, maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            text
            linkText
            date(formatString: "DD/MM/YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
