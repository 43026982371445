import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"


const News = ({ data, bg }) => {

  return (
    <StyledNews fluid={bg.childImageSharp.fluid} backgroundColor={`#000000`}> 
      <div className="newsList">
        {data.map((data, i)=>(
          <Link to={"/noticias/" + data.node.fields.slug} key={'noticia'+data.node.fields.slug} className="newsLink">
            <Img
              alt={data.alt}
              fluid={data.node.frontmatter.image.childImageSharp.fluid}
              style={{ width: `100%` }}
              imgStyle={{ objectFit: "cover" }}
            />
            <h4 className={font.oR + " tlt"}>{data.node.frontmatter.title}</h4>
            <p className={font.oEL + " date"}>{data.node.frontmatter.date}</p>
            <p className={font.oL + " text"}>{data.node.frontmatter.text}</p>
          </Link>
        ))}
      </div>
    </StyledNews>
  )
}

export default News

const StyledNews = styled(BackgroundImage)`
  position: relative;

  background-repeat: no-repeat;
  background-position: center center !important;
  background-size: cover;
  background-attachment: fixed;
  margin-top: 85px;
  z-index: 0;

  @media only screen and (max-width: 500px){
    margin-top: 0px;
  }

  .newsTitle{
    margin: 3vh 0;
    text-align: center;
    h1{margin-bottom: 0.3em;}
  }
  .newsList{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1200px;
    margin: auto;
    grid-gap: 30px;
    .bigOne{
      grid-column-start: 1;
      grid-column-end: 4;
    }
    .newsLink{
      .gatsby-image-wrapper{
        height: 230px;
      }
    }
    .bigOne, .newsLink{
      .tlt{
        margin-top: 0.5em;
      }
      .date{
        margin-bottom: 0.5em;
        color: #ADADAD;
      }
      .text{
        color: #777777;
      }
    }
  }

  @media only screen and (max-width: 1300px){
    .newsList{
      width: 90%;
      .newsLink{
        .gatsby-image-wrapper{
          height: 17vw;
        }
      }
    }
  }
  @media only screen and (max-width: 1050px){
    .newsList{
      width: 90%;
      grid-template-columns: 1fr 1fr;
      .bigOne{
        grid-column-start: 1;
        grid-column-end: 3;
      }
      .newsLink{
        .gatsby-image-wrapper{
          height: 26vw;
        }
      }
    }
  }
  @media only screen and (max-width: 768px){
    padding: 0px 0 100px;
    .newsList{
      width: 90%;
      grid-template-columns: 1fr;
      .bigOne{
        grid-column-start: 1;
        grid-column-end: 2;
      }
      .newsLink{
        .gatsby-image-wrapper{
          height: unset;
        }
      }
    }
  }
`